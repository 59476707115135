import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import EmailCaptureForm from "../components/EmailCaptureForm"
import Seo from "../components/seo"

import Layout from "../components/layout"

const AboutPage = ({ data }) => (
  <Layout>
    <Seo
      title="About — Social Impact Magazine"
      keywords={[
        `Social Impact Magazine`,
        `Entrepreneurship Magazine`,
        `Entrepreneur Magazine`,
      ]}
    />
    <main className="main" role="document">
      <section className="about-magazine  [ section  section--neutral ]">
        <div className="container">
          <aside className="row  shell-flex-parent--end-main">
            <div className="[ column  column--xs-offset-1  column--xs-5  column--sm-offset-0  column--sm-3  hidden--desktop ]">
              <div className="margin-right--2">
                <figure className="[ impression  impression--small ]">
                  <div className="[ color-overlay  color-overlay--neutral ]" />
                  <div className="desaturate">
                    <StaticImage src="../images/entrepreneur-woman.jpg/" alt="Entrepreneur Woman" placeholder="blurred" />
                  </div>
                </figure>
              </div>
            </div>
          </aside>
          <aside className="row  about-magazine__content-header  margin-bottom--10">
            <div className="[ column  column--xs-6  column--lg-4 ]">
              <div className="margin-top--20">
                <hgroup className="headlines">
                  <h4 className="[ headline  headline--uppersmall ]  margin-bottom--1">
                    Why does this magazine exist?
                  </h4>
                  <hr className="[ hr  hr--uppersmall ]  margin-bottom--3" />
                  <h2 className="[ headline  headline--beta ]  margin-bottom--4">
                    By telling the stories of true change for good we can
                    motivate others to do the same
                  </h2>
                </hgroup>
                <div className="[ description  description--hero ]">
                  <p>
                    We started Social Impact Magazine because we want more
                    people being aware of true change for good. The stories we
                    write are about changemakers, entrepreneurs, business
                    owners, leaders, artists, writers, lawyers, activists,
                    philosophers, teachers, builders, researchers or scientists
                    who are using out-of-the-box thinking to create a more
                    social and sustainable environment. By telling their story,
                    we motivating others to do the same. We are invested in the
                    new wave of responsible business leaders and social
                    entrepreneurs. Social Impact Magazine is a catalyst for
                    positive change.
                  </p>
                </div>
              </div>
            </div>
            <div className="[ column  column--xs-6  column--lg-2 ]  hidden--mobile">
              <div className="margin-right--2">
                <figure className="[ impression  impression--small ]">
                  <div className="[ color-overlay  color-overlay--neutral ]" />
                  <div className="desaturate">
                    <StaticImage src="../images/entrepreneur-woman.jpg/" alt="Entrepreneur Woman" placeholder="blurred" />
                  </div>
                </figure>
              </div>
            </div>
          </aside>
          <aside className="row  about-magazine__key-content  margin-bottom--10">
            <div className="[ column  column--xs-1  column--sm-3  column--lg-2 ]  position--relative">
              <div className="headlines--alternative">
                <h5 className="[ headline  headline--alternative ]">
                  <span className="headline--alternative__transform">
                    Our values
                  </span>
                </h5>
              </div>
            </div>
            <div className="[ column  column--xs-5  column--sm-3  column--lg-2 ]">
              <div className="[ line  line--border-top-thicker ]">
                <h3 className="[ headline  headline--gamma-column ]  margin-top--2  margin-bottom--2">
                  Genuine take on social impact
                </h3>
                <div className="[ description  description--small ]  margin-bottom--6">
                  <p>
                    We want to see for real how organizations, businesses or
                    individuals’ actions affect the surrounding community. Here
                    at Social Impact Magazine, we do not let up until we truly
                    understand the effects these companies have on society and
                    the environment.
                  </p>
                </div>
              </div>
            </div>
            <div className="[ column  column--xs-offset-1  column--xs-5  column--sm-offset-0  column--sm-3  column--lg-2 ]">
              <div className="[ line  line--border-top-thicker ]">
                <h3 className="[ headline  headline--gamma-column ]  margin-top--2  margin-bottom--2">
                  Open and direct
                </h3>
                <div className="[ description  description--small ]  margin-bottom--6">
                  <p>
                    We go deep and personal and are not afraid to ask difficult
                    questions. Our mission is to show the unpolished side of
                    reaching a social impact but also all the failures in
                    between.
                  </p>
                </div>
              </div>
            </div>
            <div className="[ column  column--xs-offset-1  column--xs-5  column--sm-offset-0  column--sm-3  column--lg-2 ]">
              <div className="[ line  line--border-top-thicker ]">
                <h3 className="[ headline  headline--gamma-column ]  margin-top--2  margin-bottom--2">
                  Balanced value-driven stories
                </h3>
                <div className="[ description  description--small ]  margin-bottom--6">
                  <p>
                    Tired of clickbait headlines and over-glorified stories?
                    None of these you will ever find in an Social Impact
                    Magazine. We care about well-crafted stories and always seek
                    to provide you information with a high value for you.
                  </p>
                </div>
              </div>
            </div>
            <div className="[ column  column--xs-offset-1  column--xs-5  column--sm-offset-0  column--sm-3  column--lg-2 ]">
              <div className="[ line  line--border-top-thicker ]">
                <h3 className="[ headline  headline--gamma-column ]  margin-top--2  margin-bottom--2">
                  Proudly independent
                </h3>
                <div className="[ description  description--small ]  margin-bottom--6">
                  <p>
                    Through getting funded by our reads and a handful selected
                    sponsors we can stay independent publications purely on the
                    value we think they provide to our readers.
                  </p>
                </div>
              </div>
            </div>
          </aside>
          <aside className="row  shell-flex-parent--end-main">
            <div className="[ column  column--xs-offset-1  column--xs-5  column--sm-offset-0  column--sm-3  hidden--desktop ]">
              <div className="margin-right--2">
                <figure className="[ impression  impression--small ]">
                  <div className="[ color-overlay  color-overlay--neutral ]" />
                  <div className="desaturate">
                    <StaticImage src="../images/founder-albert-schiemann.jpg/" alt="Founder Albert Schiemann" placeholder="blurred" />
                  </div>
                </figure>
              </div>
            </div>
          </aside>
          <aside className="row  about-magazine__content-header  margin-bottom--10">
            <div className="[ column  column--xs-6  column--lg-4 ]">
              <div className="margin-top--20">
                <hgroup className="headlines">
                  <h4 className="[ headline  headline--uppersmall ]  margin-bottom--1">
                    Who are we?
                  </h4>
                  <hr className="[ hr  hr--uppersmall ]  margin-bottom--3" />
                  <h2 className="[ headline  headline--beta ]  margin-bottom--4">
                    A one-man + contributers magazine
                  </h2>
                </hgroup>
                <div className="[ description  description--hero ]">
                  <p>
                    Albert Schiemann is a German full-time entrepreneur, author,
                    speaker and business coach with a background in graphic and
                    customer experience design, currently living in the
                    beautiful city of Utrecht, Netherlands. He has a deep
                    passion for creating outstanding products and always
                    pursuing a trustful and reliable relationship with his
                    clients.
                  </p>
                  <p>
                    He is not only conducting and editing all interviews but
                    also designing the magazine. Social Impact Magazine largely
                    remains a one-man operation and a proudly independent
                    magazine supported by readers, patrons, and sponsors.
                  </p>
                </div>
              </div>
            </div>
            <div className="[ column  column--xs-6  column--lg-2 ]  hidden--mobile">
              <div className="margin-right--2">
                <figure className="[ impression  impression--small ]">
                  <div className="[ color-overlay  color-overlay--neutral ]" />
                  <div className="desaturate">
                    <StaticImage src="../images/founder-albert-schiemann.jpg/" alt="Founder Albert Schiemann" placeholder="blurred" />
                  </div>
                </figure>
              </div>
            </div>
          </aside>
          <aside className="row  about-magazine__impressions">
            <div className="[ column  column--xs-2  column--lg-2 ]  shell-flex-child--align-bottom">
              <div className="margin-right--2">
                <figure className="[ impression  impression--small ]">
                  <div className="[ color-overlay  color-overlay--call-to-action ]" />
                  <div className="desaturate">
                    <StaticImage src="../images/woman-leader-approving.jpg/" alt="Woman leader approving" placeholder="blurred" />
                  </div>
                </figure>
              </div>
            </div>
            <div className="[ column  column--xs-4  column--lg-4 ]">
              <div className="margin-right--2">
                <figure className="[ impression  impression--small ]">
                  <div className="[ color-overlay  color-overlay--call-to-action ]" />
                  <div className="desaturate">
                    <StaticImage src="../images/entrepreneur-with-coffee.jpg/" alt="Entrepreneur with coffee" placeholder="blurred" />
                  </div>
                </figure>
              </div>
            </div>
          </aside>
        </div>
      </section>
      <section
        idName="email"
        className="email-updates  [ section  section--call-to-action ]"
      >
        <div className="container">
          <div className="row">
            <div className="[ column  column--xs-6  column--sm-2  column--lg-2 ]">
              <div className="margin-right--2  position--relative">
                <figure className="[ impression  impression--small ]">
                  <div className="[ color-overlay  color-overlay--call-to-action ]" />
                  <div className="desaturate">
                    <StaticImage src="../images/man-lay-wall.jpg/" alt="Man lay on wall" placeholder="blurred" />
                  </div>
                </figure>
                <div className="headlines--alternative  absolute-margin-top--2  hidden--mobile">
                  <h5 className="[ headline  headline--alternative ]">
                    <span className="headline--alternative__transform">
                      Subscribe
                    </span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="[ column  column--xs-6  column--sm-4  column--lg-4 ]  margin-bottom--10">
              <hgroup className="headlines  margin-top--20  margin-bottom--4">
                <h2 className="[ headline  headline--beta ]">
                  A thoughtful weekly newsletter helping you feel inspired, learn new traits, and think from new perspectives
                </h2>
              </hgroup>
              <EmailCaptureForm className="email-updates-form  [ newsletter-signup  newsletter-signup--large ]  width--100  margin-bottom--0" />
            </div>
          </div>
        </div>
      </section>
    </main>
  </Layout>
)

export default AboutPage